<template>
  <v-row class="text-center welcome">
    <v-col cols="12" md="6" offset-md="3">
      <h1 class="font-weight-bold welcome-text font-family-raleway-medium">
        {{ $t("welcome_to") }}
      </h1>
      <img :src="imgLogo" class="mt-2" />
      <p class="desc1">
        {{ $t("welcome_page_descs") }}
      </p>
      <p class="desc1">
        To use our platform, please create a new Google Ads account or link your existing account below:
      </p>
      <v-btn class="account-btn font-family-raleway-medium" to="/create-account">
        <v-icon class="mx-3"> mdi-account-plus-outline</v-icon>
        {{ $t("create_new_account") }}
      </v-btn>
      <p class="pa-0 mx-0 mt-5 mb-2">{{ $t("or") }}</p>
      <v-btn class="account-btn font-family-raleway-medium" @click="handleConnect" :loading="isLinking">
        <img :src="imglinkadd" class="mx-3" height="25" width="25" />
        {{$t("links_existing")}}
      </v-btn>
    </v-col>
  </v-row>
</template>
<script>
import imgLogo from "../../assets/icon/Pivotadd_black.svg";
import imglinkadd from "@/assets/img/linkExistingAdButtonLogo.webp";
import { accountService } from "@/services/account";

export default {
  name: "Welcome",
  data: () => ({
    imgLogo,
    imglinkadd,
    isLinking: false,
  }),
  methods: {
    async handleConnect() {
      this.isLinking = true;
      try {
        const response = await accountService.getAccountsLinkAccountHandle();
        window.location.href = response?.data;
      } catch (err) {
        console.log(err);
      }
      this.isLinking = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.welcome {
  .welcome-text {
    margin-top: 90px !important;
    font-size: 36px !important;
  }

  .desc1,
  .desc2 {
    font-size: 16px;
    margin-top: 20px;
  }
}

.account-btn {
  border-radius: 20px !important;
  width: auto;
  height: 60px !important;
  text-transform: none !important;
  font-size: 16px;
  margin-top: 10px;
  box-shadow: 0px 4px 24px rgba(34, 41, 51, 0.1) !important;
  border: 1px solid #dedbe3 !important;
  @media screen and (max-width: 500px) {
    width: 300px;
  }
}
</style>
